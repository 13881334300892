<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style lang="scss">
body,
p {
  margin: 0;
}
</style>
