<template>
  <div>
    <navbar></navbar>
    <div>
      <el-carousel
        height="100vh"
        direction="vertical"
        :autoplay="false"
        ref="slideCarousel"
        :loop="false"
        indicator-position="none"
        :active-index="activeIndex"
        @change="updataActiveIndex"
      >
        <el-carousel-item
          v-for="(item, index) in businessProfileList"
          :key="index"
        >
          <div
            class="business_item"
            :style="{ '--background-img': 'url(' + item.img + ')' }"
          >
            <div class="page_one" v-show="index == 0">
              <!-- 公司名称 -->
              <div class="page_one_title"></div>
              <!-- END -->
              <div class="page_one_introduce">
                <div>一站式人才培养体系</div>
                <div class="business">
                  <span>职称评审</span>
                  <span>建工培训</span>
                  <span>职业教育</span>
                  <span>深圳入户</span>
                  <span>香港优才</span>
                </div>
              </div>
              <img class="below" src="@/assets/index/below.png" alt="" />
              <span class="below_tip">向上滑动</span>
            </div>
            <div class="page_two" v-show="index != 0">
              <div class="page_two_title">{{ item.title }}</div>
              <div class="page_two_text">
                {{ item.content }}
              </div>
              <div class="page_two_more" @click="funRouter(item.url)">
                查看详情
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- 业务导航 -->
      <div class="progress_bar" v-show="activeIndex != 0">
        <div
          class="progress_bar_item"
          v-for="(businessListItem, businessListIndex) in businessList"
          :key="businessListIndex"
        >
          <div
            class="progress_bar_title"
            :class="
              activeIndex == businessListIndex + 1 ? 'select_bar_title' : ''
            "
          >
            <span>{{ businessListItem }}</span>
            <img
              :src="
                activeIndex == businessListIndex + 1
                  ? require('@/assets/index/blue_circle.png')
                  : require('@/assets/index/gray_circle.png')
              "
            />
          </div>
          <img
            class="progress_bar_dashed_line"
            src="@/assets/index/dashed_line.png"
            v-if="businessListIndex + 1 != businessList.length"
          />
        </div>
        <img class="down" ref="downImg" src="@/assets/index/down.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "../components/navbar.vue";
export default {
  name: "index",
  components: {
    navbar,
  },
  data() {
    return {
      moreList: false, // 是否显示业务列表
      businessProfileList: [
        {
          title: "鲁班大课堂",
          content: "",
          img: require("../assets/index/one.jpg"),
          url: "/",
        },
        {
          title: "职称评审",
          content:
            "我们拥有专业的团队和丰富的经验，能够为客户提供全方位的申请指导和支持，协助客户完成申请材料的准备和审核工作，确保客户的申请能够通过专业的评审，帮助客户获得相应的职称证书。",
          img: require("../assets/index/six.jpg"),
          url: "/ProfessionalReview",
        },
        {
          title: "建工培训",
          content:
            "我们以培养新时代工匠为发展方向，始终秉承打”全流程一站式”服务的教学解决方案，根据市场需求，凝聚了国内知名的教育领域专家，课程包含建筑设计、建筑施工、建筑管理、智慧建筑等上百门课程，截至目前，累积为社会培养了20万名技术过硬、持证上岗的优秀建工人才。",
          img: require("../assets/index/six.jpg"),
          url: "https://class.lbdkt.com/#/",
        },
        {
          title: "职业教育",
          content:
            "我司提供大量在线课程供客户观看，内容涵盖各个领域，包括注册建造师、安全工程师、注册造价师、监理工程师等。我们的课程由资深的行业专家和教育专家授课，内容丰富、权威性强，能够为客户提供专业的知识和技能支持。客户可以根据自己的需求和兴趣选择相应的课程，随时随地进行学习，提升自己的职业技能和竞争力。",
          img: require("../assets/index/six.jpg"),
          url: "http://pc.edu.lbdkt.com/",
        },
        {
          title: "深圳入户",
          content:
            "我们提供专业的深圳入户服务，帮助客户顺利完成申请流程，获得深圳市的居住资格。我们拥有专业的团队和丰富的经验，能够为客户提供全方位的申请指导和支持，确保客户顺利通过审核，获得入户资格。",
          img: require("../assets/index/six.jpg"),
          url: "/ShenZhenSettle",
        },
        {
          title: "香港移居",
          content:
            "我们公司提供专业的香港移居服务，帮助符合条件的人士实现移居香港的梦想。我们的服务包括投资移民、专才移民、创业移民以及配偶、子女和父母团聚移民等。我们拥有经验丰富的团队，为客户提供全方位的服务，包括评估申请资格、制定申请计划、协助准备文件、指导申请流程等。我们的目标是为客户提供最高品质的服务，确保申请成功，并帮助您和您的家人在香港顺利融入生活。",
          img: require("../assets/index/six.jpg"),
          url: "/HongKongMigrate",
        },
      ], // 业务详情列表
      businessList: [
        "职称评审",
        "建工培训",
        "职业教育",
        "深圳入户",
        "香港移居",
      ], // 业务列表
      activeIndex: 0, // 轮播图索引
    };
  },

  mounted() {
    this.slideBanner();
  },

  methods: {
    /**
     * @description 切换轮播图时触发
     * @param {Number} newIndex 轮播图当前索引
     * @param {Number} oldIndex 轮播图切换前索引
     * @author suxiaojun
     * @date 2023/09/04
     *  */
    updataActiveIndex(newIndex, oldIndex) {
      // console.log("oldIndex", oldIndex);
      this.activeIndex = newIndex;
      if (newIndex + 1 == this.businessProfileList.length) {
        this.$refs.downImg.classList.add("upper");
      } else {
        this.$refs.downImg.classList.remove("upper");
      }
    },
    // 滑动切换
    /**
     * @description 滑动屏幕切换轮播图
     * @author suxiaojun
     * @date 2023/09/01
     **/
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(".el-carousel__container");
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener("touchstart", (e) => {
        //手指点击位置的Y坐标
        startPoint = e.changedTouches[0].pageY;
      });
      //手指滑动
      box.addEventListener("touchmove", (e) => {
        //手指滑动后终点位置Y的坐标
        stopPoint = e.changedTouches[0].pageY;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", () => {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },

    /**
     * @description 显示更多业务列表
     * @author suxiaojun
     * @date 2023/09/01
     **/
    showMoreList() {
      this.moreList = !this.moreList;
    },
    /**
     * @description 展开列表
     * @author suxiaojun
     * @date 2023/09/01
     **/
    expandList(index, length) {
      if (length == 0) {
        // this.$router.push({ path: "/newsDetail", query: { id } });
      }
      if (this.$refs.navListChild[index].style.maxHeight != "150px") {
        this.$refs.navListChild[index].style.maxHeight = "150px";
      } else {
        this.$refs.navListChild[index].style.maxHeight = "0px";
      }
    },
    /**
     * @description 展开列表
     * @author suxiaojun
     * @date 2023/09/01
     **/
    funRouter(url) {
      if (url && url.indexOf("http") != -1) {
        window.open(url, "_blank");
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.business_item {
  position: relative;
  width: 100vw;
  height: 100vh;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: var(--background-img);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .page_one {
    width: 100vw;
    height: 100vh;
    .page_one_title,
    .page_one_introduce,
    .below,
    .below_tip {
      transform: translate(-50%, 0);
    }

    .page_one_title {
      position: absolute;
      top: 23%;
      left: 50%;
      width: 65%;
      height: 109px;
      background-image: url(../assets/index/clogo.png);
      background-size: cover;
      // text-align: center;
      // font-size: 43px;
      // font-weight: bold;
      // letter-spacing: 5px;
      // background: linear-gradient(45deg, #2f99ff, #fae197);
      // background-clip: text;
      // -webkit-text-fill-color: transparent;
    }

    .page_one_introduce {
      position: relative;
      top: 74%;
      left: 50%;
      width: 100%;
      color: #ffffff;
      font-size: 18px;
      text-align: center;

      .business {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 auto;
        gap: 10px;
        width: 80%;
      }

      div {
        padding: 5px 0;
      }
    }

    .below {
      position: absolute;
      left: 50%;
      bottom: 52px;
      width: 2.25rem;
      height: 2.25rem;
      animation: below 1s linear infinite;
    }

    @keyframes below {
      0% {
        bottom: 52px;
      }

      50% {
        bottom: 46px;
      }

      100% {
        bottom: 52px;
      }
    }

    .below_tip {
      position: absolute;
      left: 50%;
      bottom: 24px;
      color: #ffffff;
    }
  }

  .page_two {
    position: absolute;
    bottom: 0;
    left: 0px;
    display: flex;
    flex-direction: column;
    padding: 0 29px 17px;
    width: 100vw;
    box-sizing: border-box;

    .page_two_title {
      margin-bottom: 20px;
      color: #ffffff;
      font-size: 26px;
      font-weight: bold;
    }

    .page_two_text {
      margin-bottom: 20px;
      color: #ffffff;
      text-indent: 2em;
      line-height: 25px;
      letter-spacing: 1px;
      font-size: 15px;
    }

    .page_two_more {
      margin-top: auto;
      padding: 5px 0 5px;
      border: 1px solid #ffffff;
      border-radius: 4px;
      width: 96%;
      color: #ffffff;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
}
.progress_bar {
  position: fixed;
  top: 10%;
  right: 10px;
  z-index: 2;

  .progress_bar_item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .select_bar_title {
      color: #c9d0af !important;
      font-size: 17px !important;
    }

    .progress_bar_title {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #ffffff;
      transition: all 1s;

      img {
        margin-left: 6px;
        width: 16px;
        height: 16px;
      }
    }

    .progress_bar_dashed_line {
      margin-left: auto;
      padding-top: 2px;
      width: 16px;
      height: 16px;
    }
  }

  .upper {
    transform: rotate(-180deg);
  }

  .down {
    position: absolute;
    right: -5px;
    bottom: -38px;
    width: 1.75rem;
    height: 1.75rem;
    transition: transform 1s;
    animation: down 1s linear infinite;
  }
  @keyframes down {
    0% {
      bottom: -38px;
    }

    50% {
      bottom: -46px;
    }

    100% {
      bottom: -38px;
    }
  }
}
</style>