import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false

const userAgent = navigator.userAgent;
// const PCurl = window.location.href.replace(/mobile.lbdkt.com/i, 'www.lbdkt.com');
const IOS = /ios/i
const Android = /Android/i
const iphone = /iphone/i
IOS.test(userAgent) || Android.test(userAgent) || iphone.test(userAgent) ? '' : window.location.href = 'https://www.lbdkt.com';


new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
}).$mount('#app')

// title
const defaultTitle = "鲁班大课堂";
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  next();
});
